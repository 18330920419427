@import url('https://fonts.googleapis.com/css?family=Avenir:400,600,700');

* {
  box-sizing: border-box;
}
.inner{
  width: 100%;
  height: 8px;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: #3C7E2D;
}
.seperator{
  width: 100%;
  height: 4px;
  border-radius: 5px;
  background-color: #3C7E2D;
  margin-top: 20px;
  margin-bottom: 20px;
}
.cardStatus{
  color: #393939;
  width: auto;   
  height: 20px;
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  padding: 22px;

}

.cardCount{
  width: auto;
  color:#393939;
  height: 48px;
  font-size: 35px;
  font-weight: bold;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 22px;
  padding-bottom: 17px;
}

.card {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background-color: white;
  height: 130px;
  width: 30%;
  border: 1px solid #3C7E2D;
  border-radius: 5px;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
  cursor: pointer;
  transition: all 0.3s ease-out;
  /* margin: 10px;
  padding: 10px; */
}



.card:hover {
  background-color:#bbf1af;
  color: white;
}

.card:active {
  background-color:#bbf1af;
  color: white;
  transform: translateY(2px);
}

.card-active {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 130px;
  width: 30%;
  border: 1px solid #3C7E2D;
  border-radius: 5px;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
  cursor: pointer;
  transition: all 0.3s ease-out;
  background-color:#bbf1af;
  color: white;
}

.cardUpload {
  display: flex;
  align-items: center; 
  justify-content: center; 
  background-color: #3c7e2d64;
  color: white;
  height: 40px;
  width: 160px;
  border: 1px solid #3C7E2D;
  border-radius: 16px;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
  cursor: pointer;
  transition: all 0.3s ease-out;
  text-align: center; 
}

.drop-area{
  display: flex;
  align-items: center;
  height: 100px;
  width: 220px;
}

.cardUpload img {
  align-items: center;
  max-width: 50 0px;
  max-height: 50px;
  object-fit: cover;
}

.cardUpload:hover {
  background-color:#1C811C88;
  color: white;
}
.cardUpload:active {
  background-color:#3C7E2D;
  color: white;
  transform: translateY(2px);
}

.Tablecard {
  
  height: auto;
  left: 348px;
  background-color: white;
  top: 245px;
  width: 100%;
  border: 1px solid #3c7e2d66;
  border-radius: 5px;
  box-shadow: 1px 4px 11px 0px #CEBDE4;
  /* margin: 10px;
  padding: 10px; */
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root{
  color: #3C7E2D !important;
  cursor: pointer;
}

.css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #7ee23f !important;
}

.css-4ff9q7{
  color: #3C7E2D !important;
}

.css-4ff9q7.Mui-active{
  color: #7ee23f !important;
}

.css-1hv8oq8-MuiStepLabel-label {
  text-align: center !important;
  font-size: 12px !important;
  margin-bottom: 30px !important;
}

.css-h2cmlr.MuiStepLabel-alternativeLabel{
  text-align: center !important;
  font-size: 12px !important;
  margin-bottom: 30px !important; 
}

.css-z7uhs0-MuiStepConnector-line {
  display: block;
  border-color: #bdbdbd13 !important;
  border-top-style: solid;
  border-top-width: 1px;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.css-95m0ql{
  display: block;
  border-color: #bdbdbd13 !important;
  border-top-style: solid;
  border-top-width: 1px;
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.css-10mg1vw-MuiStepper-root {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.css-1187icl{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  justify-content: space-between !important;
  -webkit-align-items: center !important;
  -webkit-box-align: center !important;
  -ms-flex-align: flex-start;
  align-items: flex-start;
}

.css-13cymwt-control{
  border: 1px solid #3C7E2D !important; 

}

.processing {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:#042804a3;
  position: fixed;
  z-index: 1000;
  padding: 100px 20px;
  overflow: auto;
}


.Tablecard-head {
 
  background-color: #3C7E2D;
  color: white;
  height: 55px;
  left: 348px;
  padding: 8px;
  text-align: left;
  top: 245px;
  width: 100%;
  border: 1px solid #3C7E2D;
  border-collapse: collapse;
  border-radius: 5px;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
  /* margin: 10px;
  padding: 10px; */
}



.Tablecard-head th, .Tablecard-head td {
  border-right: 1px solid #cccccc9c;
  width: 100%;
  padding: 8px;
  height: 55px;
  text-align: left;
  
}


.innerSearch{
  width: 100%;
  height: 4px;
  top: 500px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #3C7E2D;
}



.inputContainer{  
  border-bottom: #393939;
  -ms-flex-align:center;
}
.searchcard{
  background-color: white;
  width: 100%;
  height: 64px;
  border-radius: 5px;
  border: 1px solid #3c7e2dca;
  box-shadow: 1px 2px 11px 0px #CEBDE4;
}

.searchtext {
  font-size: 16px;
  font-weight: 500;
  line-height: 16px;
  color: #3C7E2D;
}
.search-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #3C7E2D;
}

.processing-text {
  font-size: 24px;
  font-weight: 500;
  line-height: 20px;
  color: white;
}

.searchinput {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #CEBDE4;
    padding: 20px;
    width: 100%; 
    height: 40px;
    margin-left: 10px;
}

.dateinput {
    border-top: none;
    border-left: none;
    color: #777;
    border-right: none;
    border-bottom: 1px solid #CEBDE4;
    padding-left: 10px;
    width: 100%; 
    height: 40px;
    margin-top: 10px;
    margin-left: 24px;
}

.search-button {
    background-color: #E8FFDA;
    border: 1px solid #3C7E2D;
    border-radius: 5px;
    color: #3C7E2D;
    padding: 10px 20px;
    width: 100%;
    height: 40px;
    margin-left: 24px;
    cursor: pointer;
}

.dropdown-input {
  width: 100%;
  height: 40px;
  color: #777;
  position: relative;
  transition: all 0.3s ease-out;
}


.dropdown-input select {
  width: 100%;
  height: 100%;
  color: #313131;
  padding-left: 10px;
  border: 1px solid #3C7E2D;
  border-radius: 4px;
  background-color: white;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
}

.dropdown-input select.invalid {
  border-color: rgb(254, 83, 83) !important;
}
.status-btn{
  background-color: white;
  width: 30%;
  height: 40px;
  
  transition: all 0.3s ease-out;
  color: #3C7E2D;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
  border-radius: 4px;
  border: 1px solid #3C7E2D;
  cursor: pointer;
}

.status-btn:hover {
  background-color:#1C811C88;
  color: white;
}

.status-btn:active {
  background-color:#3C7E2D;
  color: white;
  transform: translateY(2px);
}

.onUrgent{
  background-color: #3C7E2D;
  color: white;
}

.upload-btn{
  width: 100%;
  text-align: start;
  padding-left: 24px;
  color: white;
  padding: 10px 40px;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
  background-color: #3C7E2D;
  height: 40px !important;
  cursor: pointer;
  transition: all 0.3s ease-out;
  border-radius: 50px;
  border: 1px solid #3C7E2D;
}
.upload-btn:hover {
  background-color:#1C811C88;
}
.upload-btn:active {
  background-color:#3C7E2D;
  transform: translateY(2px);
}
.download-btn{
  width: 188px;
  text-align: start;
  padding-left: 24px;
  color: white;
  background: url('download icon.png') no-repeat center center;
  background-size: 20px 20px;
  background-position: bottom 50% right 20px;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
  background-color: #3C7E2D;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  border-radius: 5px;
  border: 1px solid #3C7E2D;
}
.download-btn:hover {
  background-color:#1C811C88;
}
.download-btn:active {
  background-color:#3C7E2D;
  transform: translateY(2px);
}

.edit-btn{
  width: 169px;
  text-align: center;
  color: white;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
  background-color: #D80303;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  border-radius: 5px;
  border: 1px solid #D80303;
}
.edit-btn:hover {
  background-color:#ec6d6d;
}
.edit-btn:active {
  background-color:#D80303;
  transform: translateY(2px);
}
  
.collapse-btn{
  width: 188px;
  color: #3C7E2D;
  background-color: #9AE28A;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #3C7E2D;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.collapse-btn:active{
  color: white;
  background-color: #3C7E2D;
  transform: translateY(2px);
}
.comment-btn{
  width: 100%;
  color: #3C7E2D;
  background-color: #E8FFDA;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #3C7E2D;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.comment-btn:active{
  color: white;
  background-color: #3C7E2D;
  transform: translateY(2px);
}
.collapse-banner{
  width: 100%;
  background-color: #E8FFDA;
  height: 60px;
  text-align: center;
  padding: 10px;
  border: 1px solid #3C7E2D;

}

.employeeDetail-banner{
  width: 100%;
  background-color:#E8FFDA;
  border: 1px solid #3c7e2dca;
  height: 60px;
  text-align: center;
  padding: 10px;

}

.reviewer-card{
  background-color: white;
  width: 100%;
  height: auto;
  border-radius: 5px;
  border: 1px solid #3c7e2dca;
  box-shadow: 1px 2px 11px 0px #CEBDE4;
}
.reviewer-table{
  width: 100%;
  border-collapse: collapse;
}
.reviewer-table th{
  border-top: 1px solid #3C7E2D;
  border-bottom: 1px solid #3C7E2D;
  color: #4D4F5B;
  background-color: #E8FFDA;
  margin-bottom: 11px;
  padding: 8px;
  height: 55px;
  text-align: left;
  
}

.reviewer-table td {
  border-bottom: 1px solid #cccccc9c;
  padding: 20px;
  height: auto;
  text-align: left;
  width: auto;
  
  
}


.submit-btn{
  width: 100%;
  color: white;
  background-color: #3c7e2d;
  height: 40px;
  cursor: pointer;
  transition: all 0.3s ease-out;
  border-radius: 5px;
  border: 1px solid #3C7E2D;
}

.submit-btn:hover {
  background-color:#1C811C88;
}
.submit-btn:active{
  background-color:#3C7E2D;
  transform: translateY(2px);
}
.check-box{
  border: 1px solid #3C7E2D;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease-out;
  cursor: pointer;
}
.check-box:active{
  border: 1px solid #3C7E2D;
  width: 20px;
  height: 20px;
  background-color: #3C7E2D;
}
.clickable-row {
  cursor: pointer;
  width: auto;
}


.save-drafts{
  width: 100%;
  color: #3C7E2D;
  background-color: #E8FFDA;
  height: 40px;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease-out;
  border-radius: 5px;
  border: 1px solid #3C7E2D;
  cursor: pointer;
}

.save-drafts:hover {
  background-color:#1C811C88;
  color: white;
}

.save-drafts:active{
  background-color:#3C7E2D;
  transform: translateY(2px);
  color: white;
}

.saved-draft{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  color: #3C7E2D;
  background-color: #ffffff;
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease-out;
  border-radius: 5px;
  border: 1px solid #3C7E2D;
  /* cursor: pointer; */
}

.card img {
  max-width: 100%;
}

.card h2 {
  font-size: 1.5rem;
}

.card p {
  font-size: 1rem;
}

.action-dropdown{
  width: 100%;
  color: #3C7E2D;
  background-color: #E8FFDA;
  height: 40px;
  text-align: center;
  padding-left: 10px;
  transition: all 0.3s ease-out;
  border-radius: 5px;
  border: 1px solid #3C7E2D;
  cursor: pointer;
}

.uploads{
  color: #771390;
  background: url('download icon.png') no-repeat center center;
  background-size: 20px 20px;
  background-position: bottom 50% right 20px;
  cursor: pointer;
}

label{
  font-size: 14px;
  font-weight: 24;
  color: #676c65;
}

.modal {
  position:fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: block;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
  overflow-y: auto;
}

.modal-content {
  background: white;
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 1.3);
}

.modal-contents {
  background: rgb(223, 223, 223);
  padding: 40px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%; 
}


.bordered-table th{
  border: 1px solid #cccccc9c;
  color: white;
  background-color: #3C7E2D;
  margin-bottom: 11px;
  border-top: none;
  padding: 8px;
  height: 50px;
  text-align: left;
  
}

.bordered-table td {
  border: 1px solid #cccccc9c;
  padding: 8px;
  height: 50px;
  text-align: left;
  width: auto;
  
  
}
.bordered-table td:last-child {
  border: 1px solid #cccccc9c;
  padding: 8px;
  height: 50px;
  text-align: center;
  width: auto;
}
.react-datepicker-wrapper {
  width: 100%;
}



.react-datepicker__close-icon::after {
  background-color: #3C7E2D !important;
}

.input-field {
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #3c7e2da6;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
}

.input-field.invalid {
  border-color: rgb(254, 83, 83); 
}


.input-fields{
  display: inline;
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #3c7e2da6;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
}

.text-area-input-field{
  width: 100%;
  height: 120px;
  padding-left: 10px;
  padding-top: 10px;
  border-radius: 4px;
  border: 1px solid #3c7e2da6;
  box-shadow: 1px 4px 11px 0px #CEBDE440;
}

.text-area-input-field.invalid {
  border-color: rgb(254, 83, 83); 
}

.bordered-table {
  width: 100%;
  border-collapse: collapse;
  
}
.pdf-container{
  width: 100%;
  height: auto;
  background-color: white;

}

.bottom-border {
  border-bottom: 3px solid  #3C7E2D;
  margin-bottom: 20px;
}

.border-test {
  border: 1px solid red;
}
.annual_button {
  border-radius: 5px 5px 0 0;
  border: 1px solid #1c811c;
  padding: 8px 15px;
  cursor: pointer;
  width: 200px;
  border-bottom: 4px solid #1c811c;
}

.close {
  color: #3c7e2d;
  background-color: #e8ffda;
  height: 40px;
  width: 40px; 
  text-align: center;
  padding: 10px;
  transition: all 0.3s ease-out;
  border-radius: 4px;
  border: 1px solid #3c7e2d;
  cursor: pointer;
  position: absolute;
  top: 10px; 
  right: 10px; 
}


.close:hover {
  background-color:#3C7E2D;
  color: #ffffff;

}

.close:active{
  background-color:#3C7E2D;
  transform: translateY(2px);
  color: #ffffff;
}

.rnc__notification-item--warning {
  background-color: #232114 !important;
  border-left: 8px solid #ce9c09;
}
.annual_button1 {
  border-radius: 5px 5px 0 0;
  border: 1px solid #e9e9f0;
  padding: 8px 15px;
  cursor: pointer;
  width: 200px;
  color: #43425d;
  opacity: 0.5;
  background-color: #f5f7f9;
}

/* .bordered-table th {
  background-color: #f2f2f2;
} */
.dashboard-container {
  max-width: 800px; /* Set a maximum width for the container */
  margin: 0 auto; /* Center the container horizontally */
  padding: 20px; /* Add padding for spacing around the card */
}


body {
  margin: 0;
  font-size: 0.9rem;
  overflow-x: hidden;
  color: #393939;
  background-color: #fff;
  font-family: 'Avenir', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6, p,
div, header, section {
  margin: 0;
}

button, input {
  border: none;
  outline: none;
}

section {
  width: 100%;
  margin: 0;
}
.react-datepicker__current-month{
  display: none;
}

.p-r {
  position: relative!important;
}

.pad {
  padding: 20px;
}

.no-padding {
  padding: 0!important;
}
.p-5 {
  padding: 5px!important;
}
.p-10 {
  padding: 10px!important;
}
.p-20 {
  padding: 20px!important;
}
.p-40 {
  padding: 40px!important;
}
.p-t-40 {
  padding-top: 40px!important;
}
.p-r-5 {
  padding-right: 5px!important;
}
.no-p-r {
  padding-right: 0px!important;
}
.no-p-l {
  padding-left: 0px!important;
}
.p-l-5 {
  padding-left: 5px!important;
}
.p-l-10 {
  padding-left: 10px!important;
}
.p-l-20 {
  padding-left: 20px!important;
}
.p-l-30 {
  padding-left: 30px!important;
}
.p-l-40 {
  padding-left: 40px!important;
}

.no-margin {
  margin: 0!important;
}
.m-l-5 {
  margin-left: 5px!important;
}
.m-l-10 {
  margin-left: 10px!important;
}
.m-l-20 {
  margin-left: 20px!important;
}
.m-l-30 {
  margin-left: 30px!important;
}
.m-l-40 {
  margin-left: 40px!important;
}
.m-l-50 {
  margin-left: 50px!important;
}
.m-l-80 {
  margin-left: 80px!important;
}
.m-l-90 {
  margin-left: 90px!important;
}
.m-r-5 {
  margin-right: 5px!important;
}
.m-r-10 {
  margin-right: 10px!important;
}
.m-r-20 {
  margin-right: 20px!important;
}
.m-r-30 {
  margin-right: 30px!important;
}
.m-r-40 {
  margin-right: 40px!important;
}
.m-r-50 {
  margin-right: 50px!important;
}
.m-r-60 {
  margin-right: 60px!important;
}
.m-t-10 {
  margin-top: 10px!important;
}
.m-t-20 {
  margin-top: 20px!important;
}
.m-t-30 {
  margin-top: 30px!important;
}
.m-t--20 {
  margin-top: -20px!important;
}
.m-t-40 {
  margin-top: 40px!important;
}
.m-t-60 {
  margin-top: 60px!important;
}
.m-t-80 {
  margin-top: 80px!important;
}
.m-t-5 {
  margin-top: 5px!important;
}
.m-b-5 {
  margin-bottom: 5px!important;
}
.m-b-10 {
  margin-bottom: 10px!important;
}
.m-b-20 {
  margin-bottom: 20px!important;
}
.m-b-30 {
  margin-bottom: 30px!important;
}
.m-b-40 {
  margin-bottom: 40px!important;
}
.m-b-50 {
  margin-bottom: 50px!important;
}
.m-b-60 {
  margin-bottom: 60px!important;
}
.bg-white{
  background-color: white;
  height: 100%;
  border-radius: 5px;
}
.no-border {
  border: none!important;
}
.no-wrap {
  flex-wrap: nowrap!important;
}
.wrap {
  flex-wrap: wrap!important;
}
.flex {
  display: flex!important;
}
.flex-col{
  flex-direction: column;
  margin-bottom: 20px;
}
.flex-h-center {
  justify-content: center!important;
}
.flex-right{
  justify-content: right!important;
}
.flex-v-center {
  align-items: center!important;
}
.flex-v-start{
  align-items: flex-start!important;

}
.pointer {
  cursor: pointer!important;
}
.underline {
  text-decoration: underline!important;
}
.center{
  justify-items: center;
}
.center-text {
  text-align: center!important;
}
.right-text {
  text-align: right!important;
}
.space-between {
  justify-content: space-between!important;
}

.space-around {
  justify-content: space-around!important;
}

.row{
  display: flex;
  flex-wrap: wrap;
  
}


.float-right {
  float: right!important;
}
.float-left {
  float: left!important;
}
.inline-block {
  display: inline-block!important;
}
.bold-text {
  font-weight: bold!important;
}

.max-w-400 {
  max-width: 400px!important;
}

.col-1, .col-2, .col-3, .col-4,
.col-5, .col-6, .col-half, .col-7, .col-8,
.col-9, .col-10, .col-11, .col-12 {
  display: inline-block;
  position: relative;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 20px;
}
.col-1 {
  width: 8.33%;
}
.col-2 {
  width: 16.66%;
}
.col-3 {
  width: 25%;
}
.col-4 {
  width: 33.33%;
}
.col-5 {
  width: 41.65%;
}
.col-6, .col-half {
  width: 50%;
}
.col-7 {
  width: 58.33%;
}
.col-8 {
  width: 66.66%;
}
.col-9 {
  width: 75%;
}
.col-10 {
  width: 83.33%;
}
.col-11 {
  width: 91.63%;
}
.col-12, .w-100 {
  width: 100%!important;
  position: relative;
}
/* .row {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + 20px);
  position: relative;
  margin-right: -10px;
  margin-left: -10px;
} */

.notify {
  background-color: #eb2e20;
  color: white; 
  padding: 15px; 
  box-shadow: 1px 2px 11px 0px #CEBDE4;
  align-self: center;
  margin-bottom: 20px;
  border-radius: 4px;
  opacity: 1; 
  transition: opacity 5.5s ease-out;
}

.notify:fade-out {
  opacity: 0;
}

.content {
  /* max-width: 1300px; */
  margin: 0 auto;
  padding: 20px;
  flex-wrap: wrap;
  display: flex;
}

footer {
  position: relative;
  background-color: #454545;
  color: #ddd;
  height: 58px;
}

.modal-container {
  background-color: #fffffff0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


div.banner {
  width: 100%;
  padding: 130px 0 100px;
  background-size: cover;
  background-blend-mode: luminosity;
  background-image: url('../images/banner.png');
  background-color: #1C811C55;
  background-position-y: 20%;
}

div.banner .login {
  margin: 0 auto;
  max-width: 600px;
  padding: 40px;
  position: unset;
  border-radius: 10px;
  background-color: #fffffff0;
}
div.login {
  display: flex;
}
div.login .coat-of-arm {
  border-right: 1px solid #C6C6C6;
  padding-top: 15px;
  padding-right: 40px;
  width: 270px;
}
div.login .coat-of-arm img {
  width: 140px;
  height: auto;
}
div.login .coat-of-arm h2 {
  font-size: 1.58rem;
  font-family: sans-serif;
  line-height: 1.5rem;
  font-weight: 700;
}
div.login .coat-of-arm h4 {
  font-size: 1.25rem;
  font-weight: 400;
}
div.login .form {
  padding-left: 40px;
  width: 100%;
  text-align: center;
}
.login-form-group {
  width: 100%;
  margin-bottom: 20px;
}
.login-form-group {
  width: 100%;
  margin-bottom: 15px;
}
.login-form-group input {
  width: 100%;
  padding: 13px;
  border: 1px solid #C6C6C6;
  border-radius: 5px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: .9rem;
  color: #777;
}
div.login .form button {
  width: 100%;
  color: #fff;
  padding: 13px;
  background-color: #017D34;
  border-radius: 5px;
  outline: none;
  border: none;
  transition: all 0.3s ease-out;
}
div.login .form button:hover {
  background-color: #017D3488;
}
.intro {
  max-width: 800px;
  margin: 30px auto 10px;
}
div.dept-news {
  background-color: #FFFFFF;
  box-shadow: 5px 3px 22px #958E8E29;
  border: 1px solid #C6C6C6;
  border-radius: 10px;
  padding: 20px;
  position: relative;
}
div.dept-news h4 {
  background-color: #1C811C;
  color: #ffffff;
  padding: 5px 10px 2px;
  display: inline-block;
  position: relative;
  right: 20px;
}
.headline {
  align-items: center;
  border-bottom: 1px solid #C6C6C6;
  padding-bottom: 20px;
}
.headline:last-child {
  border-bottom: none;
  padding-bottom: 0;
}
.headline .date {
  background-color: #1C811C1F;
  padding: 5px 10px;
  text-align: center;
}
.headline .date .m {
  font-size: 0.7rem;
}
.headline .date .d {
  font-size: 1.4rem;
}
.headline .title {
  margin-left: 10px;
}
div.bottom {
  border-left: 1px solid #C6C6C6;
  padding: 0 20px;
}
div.bottom h2 {
  color: #1C811C;
  font-family: sans-serif;
  margin-bottom: 20px;
}
div.bottom ol {
  padding-left: 20px;
}
div.bottom ul {
  padding: 0;
  list-style: none;
}




@media only screen and (max-width: 900px) {
  .col-8,
  .col-7,
  .col-6 {
    width: 100%;
    flex: 1 0 100%;
  }
  .col-5,
  .col-4,
  .col-3 {
    width: 50%;
    flex: 1 0 50%;
  }
}

@media only screen and (max-width: 600px) {
  .col-5,
  .col-4,
  .col-3 {
    width: 100%;
    flex: 1 0 100%;
  }
  div.banner {
    padding-top: 60px;
  }
  div.login {
    display: block;
    margin: 20px!important;
  }
  div.login .coat-of-arm {
    border-right: none;
    padding-bottom: 20px;
    padding-right: 0;
    text-align: center;
    width: 100%;
  }
  div.login .form {
    padding-left: 0;
  }
}
